import React from 'react';
import { graphql } from 'gatsby';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Reference,
    Content,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../../components/InnerPage';

const Bahaullah18171892 = ({ data }) => (
    <InnerPage
        title="Bahá’u’lláh (1817-1892)"
        description="Leben und Wirken Bahá’u’lláhs, des Stifters der Bahá’í-Religion"
    >
        <IntroHolder
            title="Bahá’u’lláh (1817-1892)"
            subtitle="Leben und Wirken Bahá’u’lláhs, des Stifters der Bahá’í-Religion"
            picture={data.introImage}
            breadcrumbs={{
                grandparent: 'Woran Bahá’í glauben',
                parent: 'Bahá’u’lláh und Sein Anspruch',
                'current-item': 'Bahá’u’lláh (1817-1892)',
            }}
        />

        <TwoColumns>
            <Content>
                <p>
                    Bahá’u’lláh bedeutet „Herrlichkeit Gottes“. In Ihm sehen die
                    Bahá’í jenen Verheißenen, auf den der Báb in Seiner Sendung
                    hinwies und der von Anhängern der verschiedensten Religionen
                    der Menschheit seit Langem erwartet wurde. Bahá’u’lláh
                    brachte den Menschen eine neue Botschaft von Gott, die der
                    ganzen Menschheit den Weg zeigt, global in Frieden zusammen
                    zu leben.
                </p>
                <div>
                    <p>
                        In tausenden Versen, Büchern und Schriftbänden
                        offenbarte Bahá’u’lláh einen Wegweiser, der die
                        Menschheit dabei unterstützt, den großen
                        Herausforderungen auf dem Weg zur Einheit der Menschheit
                        zu begegnen und eine Zivilisation aufzubauen, die ein
                        weltweit gerechtes, materielles Wohlergehen mit einer
                        geistig-spirituellen Ausrichtung des Menschen verbindet.
                        <br />
                        Bahá’u’lláh zählte zunächst zu den Anhängern des Báb bis
                        er 1852 – bereits in Kerkerhaft in Teheran – durch eine
                        Vision Seine Berufung als göttlich Verheißener und
                        Erneuerer der Religion erfuhr. Kurz danach begann ein
                        40-jähriger weiter Weg der Verbannung aus Seinem
                        Geburtsland über Bagdad und Konstantinopel bis nach Akká
                        bei Haifa, der letzten Station Seines Wirkens.
                    </p>
                </div>
                <h2>Frühzeit</h2>
                <div>
                    <p>
                        Als Bahá’u’lláh, mit Geburtsnamen Mírzá Husayn-ʻAlí,
                        1817 in Teheran geboren wurde, genoss er alle Vorteile
                        einer Kindheit am Königshof. Von sehr frühem Alter an
                        zeigte Er Weisheit und außergewöhnliches Wissen.
                        <br />
                        Anstatt als junger Mann eine Laufbahn im Staatsdienst
                        anzustreben, wie es Sein Vater getan hatte, entschied
                        sich Mírzá Husayn-ʻAlí, Seine Energien der Fürsorge für
                        die Armen zu widmen. Er zeigte kein Interesse daran,
                        Rang und Namen zu erlangen.
                        <br />
                        Durch die Annahme der Religion des Báb veränderte sich
                        das Leben des jungen Edelmanns und Seiner Familie
                        nachhaltig. Obwohl Er den Báb persönlich nie getroffen
                        hatte, erklärte Mírzá Husayn-ʻAlí von dem Moment an, da
                        Er Seine Botschaft vernahm, Seinen uneingeschränkten
                        Glauben an Ihn und nutzte Seine ganze Kraft und Seinen
                        Einfluss zu dessen Förderung.
                        <br />
                        Im Jahr 1848 fand in dem Dorf Badascht im Nordosten
                        Persiens eine wichtige Versammlung der Anhänger des Báb
                        statt. Mírzá Husayn-ʻAlí spielte eine zentrale Rolle in
                        den Geschehnissen, die den eigenständigen Charakter der
                        neuen Bábi-Religion und ihre Unabhängigkeit vom Islam
                        bestätigten. Von diesem Zeitpunkt an wurde Mírzá
                        Husayn-ʻAlí als Bahá’u’lláh bekannt, der arabische
                        Ausdruck für „Herrlichkeit Gottes“.
                    </p>
                </div>
                <div>
                    <p>
                        <img
                            alt=""
                            src={data.badashtTehran.childImageSharp.fluid.src}
                            className="aligncenter"
                        />
                    </p>
                </div>
                <h2>Offenbarung</h2>
                <div>
                    <p>
                        Im Jahr 1852 wurde Bahá’u’lláh fälschlicherweise der
                        Mittäterschaft bei einem Anschlag auf das Leben des
                        Herrschers, Násiri’d-Din Sháh, bezichtigt und in einem
                        berüchtigten unterirdischen Verlies, bekannt als das
                        „Schwarze Loch“, eingekerkert.
                        <br />
                        Das Verlies war einst ein Wasserspeicher für das
                        öffentliche Bad. Innerhalb dieser Mauern schmachteten
                        die Gefangenen in der kalten, ungesunden Luft dahin,
                        aneinander gekettet durch eine unerträglich schwere
                        Kette, die für den Rest Seines Lebens Spuren auf
                        Bahá’u’lláhs Körper hinterließ.
                        <br />
                        An diesem düsteren Schauplatz trug sich erneut ein
                        seltenes und kostbares Ereignis zu: ein sterblicher
                        Mensch, äußerlich Mensch in jeder Hinsicht, wurde von
                        Gott auserwählt, um der Menschheit eine neue Botschaft
                        zu bringen.
                        <br />
                        Diese Erfahrung der göttlichen Offenbarung
                        veranschaulicht Bahá’u’lláh in eigenen Worten: „In den
                        Tagen, da ich im Kerker in Teheran lag, vergönnten Mir
                        die schweren Ketten, die Mich wundrieben, und die üble
                        Luft nur wenig Schlaf; dennoch hatte ich in den seltenen
                        Augenblicken des Schlummers ein Gefühl, wie wenn etwas
                        vom Scheitel Meines Hauptes über Meine Brust strömte,
                        einem mächtigen Sturzbach gleich, der sich vom Gipfel
                        eines hohen Berges zu Tal ergießt. … Meine Zunge sprach
                        in solchen Augenblicken Worte, die zu hören kein Mensch
                        hätte ertragen können.“<sup>1</sup>
                    </p>
                </div>
                <h2>Verbannung nach Bagdad</h2>
                <div>
                    <p>
                        Nach vier Monaten schweren Leidens unter der
                        Einkerkerung wurde Bahá’u’lláh krank und völlig
                        erschöpft freigelassen, für immer aus Seiner Heimat
                        Persien verbannt und mit Seiner Familie nach Bagdad
                        geschickt. Dort wandten sich die übrigen Anhänger des
                        Báb in moralischen und geistigen Fragen immer mehr an
                        Bahá’u’lláh. Die Vornehmheit Seines Charakters, die
                        Weisheit Seines Rates, die Güte, die Er allen erwies,
                        und die zunehmenden Beweise übermenschlicher Größe in
                        Ihm belebten die grausam unterdrückte Gemeinde.
                        <br />
                        Die Bedeutung der Gemeinde wuchs und Sein Ansehen
                        verbreitete sich noch stärker. Drei Seiner bekanntesten
                        Werke entstanden in dieser Zeit – die Verborgenen Worte,
                        die Sieben Täler und das Buch der Gewissheit
                        (Kitáb-i-Íqán). Obwohl Bahá’u’lláhs Schriften bereits
                        auf Seine Stellung hinwiesen, so war jedoch der
                        Zeitpunkt für eine öffentliche Verkündigung Seines
                        Anspruchs als Gottesoffenbarer noch nicht erreicht.
                        <br />
                        Als Bahá’u’lláhs Ruf sich verbreitete, wurden Neid und
                        Böswilligkeit einiger Geistlicher neu entfacht. Beim
                        Schah Persiens beantragten sie, den osmanischen Sultan
                        zu bitten, Bahá’u’lláh in größere Entfernung von der
                        persischen Grenze zu verbringen. Eine zweite Verbannung
                        wurde angeordnet.
                        <br />
                        Ende April 1863, kurz vor der Abreise aus der Umgebung
                        von Bagdad nach Konstantinopel, wohnte Bahá’u’lláh mit
                        Seiner Familie und Seinen Gefährten zwölf Tage lang in
                        einem Garten, den Er „Ridván“ nannte, was „Paradies“
                        bedeutet. Dort, an den Ufern des Flusses Tigris,
                        verkündete Bahá’u’lláh öffentlich, dass Er der vom Báb
                        angekündigte und in den Schriften der Religionen
                        vorausgesagte Bote Gottes für ein Zeitalter der ganzen
                        Menschheit sei, in dem Tyrannei und Ungerechtigkeit der
                        Vergangenheit einer Welt des Friedens und der
                        Gerechtigkeit weichen würden.
                    </p>
                </div>
                <div>
                    <p>
                        <img
                            alt=""
                            src={data.verbannungsweg.childImageSharp.fluid.src}
                            className="aligncenter"
                        />
                    </p>
                </div>
                <h2>Weitere Verbannung</h2>
                <div>
                    <p>
                        Drei Monate nach der Abreise aus Bagdad erreichten
                        Bahá’u’lláh und Seine Gefolgschaft Konstantinopel. Sie
                        blieben dort nur vier Monate, dann folgte eine erneute
                        Verbannung nach Adrianopel (dem heutigen Edirne). Es war
                        eine äußerst anstrengende Reise in der kältesten Zeit
                        des Winters, und ihre Unterkunft in Adrianopel bot
                        keinen Schutz vor der bitteren Kälte.
                        <br />
                        Beginnend mit dem Jahr 1867 richtete Bahá’u’lláh eine
                        Reihe inhaltsvoller Sendschreiben an die wichtigsten
                        weltlichen und religiösen Herrscher der damaligen Zeit,
                        unter ihnen Kaiser Napoleon III., Königin Victoria,
                        Kaiser Wilhelm I., Sultan `Abdu’l-`Aziz, Násiri’d-Din
                        Sháh, Papst Pius IX. und die Präsidenten Amerikas. In
                        diesen vorausschauenden Schriften verkündete Er offen
                        Seinen Anspruch und sprach vom Anbruch eines neuen
                        Zeitalters. Aber zunächst, so warnte Er, würde es
                        katastrophale Umwälzungen in der politischen und
                        gesellschaftlichen Ordnung der Welt geben. Er rief die
                        herrschenden Oberhäupter auf, Gerechtigkeit zu wahren
                        und eine Versammlung einzuberufen, die den Kriegen
                        dauerhaft ein Ende setzen würde. Nur durch gemeinsames
                        Handeln, sagte Er, könne ein dauerhafter Friede
                        geschaffen werden. Seine Warnungen trafen auf taube
                        Ohren.
                        <br />
                        Fortgesetzte Anfeindungen durch Bahá’u’lláhs Gegner
                        veranlassten die osmanische Regierung zu einer weiteren,
                        letzten Verbannung in ihre entlegenste Strafkolonie, der
                        Gefängnisstadt Akká in der Bucht von Haifa, wo
                        Bahá’u’lláh am 31. August 1868 eintraf. Nachdem Er und
                        Seine Gefolgschaft über zwei Jahre in der Zitadelle von
                        Akká in Kerkerhaft verbracht hatten, konnten sie in ein
                        Haus innerhalb der Stadtmauern umziehen. Nach und nach
                        bewirkten die Vornehmheit und Würde Bahá’u’lláhs, dass
                        sich die anfängliche Verspottung durch die
                        Öffentlichkeit in zunehmende Bewunderung umwandelte,
                        auch von Seiten einiger ihrer Anführer.
                        <br />
                        In ʻAkká offenbarte Bahá’u’lláh Sein wichtigstes Werk,
                        das Kitáb-i-Aqdas (das Heiligste Buch), in dem Er die
                        grundlegenden Gesetze und Prinzipien Seines Glaubens
                        umriss und die Grundlagen für eine weltumspannende
                        Gemeindeordnung legte.
                        <br />
                    </p>
                </div>
                <div>
                    <p>
                        <img
                            alt=""
                            src={
                                data.weitereVerbannung.childImageSharp.fluid.src
                            }
                            className="aligncenter"
                        />
                    </p>
                </div>
                <h2>Letzte Lebensperiode</h2>
                <div>
                    <p>
                        In den späten 1870er Jahren wurde Bahá’u’lláh – noch als
                        Gefangener – die Freiheit gewährt, sich auch außerhalb
                        der Stadtmauern zu bewegen, bis Er schließlich in einem
                        Landhaus bei ʻAkká Seinen Wohnsitz nehmen konnte. Dies
                        ermöglichte es den Gläubigen, mit Ihm ungestört und in
                        größerer Ruhe zusammenzutreffen. Im April 1890 traf
                        Professor Edward Granville Browne von der Universität
                        Cambridge Bahá’u’lláh in diesem Landhaus. Browne schrieb
                        über dieses Zusammentreffen: „Das Antlitz dessen, den
                        ich erblickte, kann ich nie vergessen, und doch vermag
                        ich es nicht zu beschreiben. Diese durchdringenden Augen
                        schienen auf dem Grund der Seele zu lesen; Macht und
                        Autorität lagen auf dieser hohen Stirn… Hier bedurfte es
                        keiner Frage mehr, vor wem ich stand, als ich mich vor
                        einem Manne neigte, der Gegenstand einer Verehrung und
                        Liebe ist, um die ihn Könige beneiden und nach der
                        Kaiser sich vergeblich sehnen!“
                        <sup>2</sup>
                    </p>
                </div>
                <div>
                    <p>
                        Bahá’u’lláh starb am 29. Mai 1892. In Seinem Testament
                        ernannte Er ‘Abdu’l-Bahá zu Seinem Nachfolger und
                        Oberhaupt des Bahá’í-Glaubens. Diese Auswahl eines
                        Nachfolgers wird der „Bund Bahá’u’lláhs“ genannt, der
                        die Grundlage für ein geeintes Fortbestehen der
                        Bahá’í-Gemeinde bildet.
                    </p>
                </div>
                <div>
                    <p>
                        <img
                            alt=""
                            src={data.bahji.childImageSharp.fluid.src}
                            className="aligncenter"
                        />
                    </p>
                </div>
                <Reference>
                    <p>1. Bahá'u'lláh, Brief an den Sohn des Wolfes 1:37</p>
                    <p>2. Shoghi Effendi, Gott geht vorüber 11:27</p>
                </Reference>
            </Content>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default Bahaullah18171892;

export const pageQuery = graphql`
    query {
        introImage: file(relativePath: { eq: "Bahji-1.jpg" }) {
            ...fluidImage
        }
        badashtTehran: file(
            relativePath: { eq: "Baháulláh_Frühzeit-mit-Text-300x300.png" }
        ) {
            ...fluidImage
        }
        verbannungsweg: file(relativePath: { eq: "Verbannungsweg.jpg" }) {
            ...fluidImage
        }
        weitereVerbannung: file(
            relativePath: { eq: "Weitere-Verbannung-mit-Text-1.png" }
        ) {
            ...fluidImage
        }
        bahji: file(relativePath: { eq: "Bahji.png" }) {
            ...fluidImage
        }
    }
`;
